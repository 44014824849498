<template>
  <div class="flex container justify-center">
    <ul
      class="mb-10 grid grid-cols-2 md:flex gap-2 md:gap-6 justify-center w-full"
    >
      <li v-for="(item, index) in items" :key="index" class="md:w-full">
        <PaperTeaser :paper="item" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const indexStore = useIndexStore()
const items = computed(() => indexStore.activePapers)
</script>
